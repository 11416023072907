<template>
  <div style="margin-left: 5px;margin-right: 5px;">
    <div class="title">
      <span style="font-size: 16px;"><strong>悄然声色隐私政策</strong></span>
    </div>
    <div class="title">
      <span style="font-size: 12px;">更新日期：2022年7月19日</span>
    </div>
    <div class="right">
      <span style="font-size: 12px;">生效日期：2022年7月20日</span>
    </div>
    <div>
      <span style="font-size: 14px;">尊敬的用户，您好！</span>
    </div>
    <div>
      <span style="font-size: 12px;">悄然声色是由北京天下在线科技有限公司（以下简称“我们”）为您提供的一款应用语音产品。我们非常重视用户的隐私和个人信息保护，并致力于为每位用户提供更安全的互联网环境。我们希望通过《悄然声色隐私政策》（以下简称“本隐私政策”）向您说明在您使用我们的产品与/或服务时，我们如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。我们深知个人信息对您的重要性，并将按照法律法规要求和业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。请您在使用我们提供的产品与/或服务前，仔细阅读并充分理解《悄然声色隐私政策》的全部内容，以便做出适当的选择。如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，您均可以通过如下任一方式与我们取得联系：</span>
    </div>
    <div>
      <span style="font-size: 12px;">1.加入用户反馈QQ群：47698888 与我们联系；</span>
    </div>
    <div>
      <span style="font-size: 12px;">2.向我们的 <span style="font-size: 12px;">47698888&nbsp;</span>@qq.com 邮箱发送邮件与我们联系；</span>
    </div>
    <div>
      <span style="font-size: 12px;">3.写信至我们办公地址：北京市海淀区信息路甲28号D座06B-6130 与我们联系；</span>
    </div>
    <div>
      <span style="font-size: 12px;">4.联系个人信息保护邮箱：476898888@qq.com 与我们联系。</span>
    </div>
    <div>
      <span style="font-size: 12px;">一般情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的十五个工作日内向您回复。</span>
    </div>
    <div>
      <span style="font-size: 12px;"><strong>本隐私政策将帮助您了解以下内容：</strong></span>
    </div>
    <ol class=" list-paddingleft-2">
      <li>
        <div>
          <a href="javascript:void(0)" style="text-decoration: underline; font-size: 12px;"><span style="font-size: 12px;">一、我们如何收集和使用您的个人信息</span></a>
        </div>
      </li>
      <li>
        <div>
          <a href="javascript:void(0)" style="text-decoration: underline; font-size: 12px;"><span style="font-size: 12px;">二、我们如何共享、转让、公开披露您的个人信息</span></a>
        </div>
      </li>
      <li>
        <div>
          <a href="javascript:void(0)" style="text-decoration: underline; font-size: 12px;"><span style="font-size: 12px;">三、我们如何保存和保护您的个人信息</span></a>
        </div>
      </li>
      <li>
        <div>
          <a href="javascript:void(0)" style="text-decoration: underline; font-size: 12px;"><span style="font-size: 12px;">四、您如何管理个人信息</span></a>
        </div>
      </li>
      <li>
        <div>
          <a href="javascript:void(0)" style="text-decoration: underline; font-size: 12px;"><span style="font-size: 12px;">五、对Cookie和同类技术的使用</span></a>
        </div>
      </li>
      <li>
        <div>
          <a href="javascript:void(0)" style="text-decoration: underline; font-size: 12px;"><span style="font-size: 12px;">六、第三方服务</span></a>
        </div>
      </li>
      <li>
        <div>
          <a href="javascript:void(0)" style="text-decoration: underline; font-size: 12px;"><span style="font-size: 12px;">七、未成年人的个人信息保护</span></a>
        </div>
      </li>
      <li>
        <div>
          <a href="javascript:void(0)" style="text-decoration: underline; font-size: 12px;"><span style="font-size: 12px;">八、通知和修订</span></a>
        </div>
      </li>
      <li>
        <div>
          <a href="javascript:void(0)" style="text-decoration: underline; font-size: 12px;"><span style="font-size: 12px;">九、如何联系我们</span></a>
          <!--<a href="#title9" style="text-decoration: underline; font-size: 12px;"><span style="font-size: 12px;">九、如何联系我们</span></a>-->
        </div>
      </li>
    </ol>
    <p>
      <span style="font-size: 12px;">请您在使用我们提供的产品与/或服务前，仔细阅读并充分理解《悄然声色隐私政策》的全部内容并作出相应选择。一旦您勾选或点击“同意”按钮或以第三方平台账号登录或实际使用我们的产品与/或服务，即视为您已充分理解并接受本隐私政策。</span>
    </p>
    <ol class="list list-paddingleft-2">
      <li>
        <div id="title1">
          <span style="font-size: 12px;">一、我们如何收集和使用您的个人信息</span>
        </div>
        <div>
          <span style="font-size: 12px;">个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。</span>
        </div>
        <div>
          <span style="font-size: 12px;">本隐私政策中涉及的个人信息包括：基本信息（包括个人姓名、生日、性别、<strong>个人电话号码</strong>、电子邮箱、城市、职业）；网络身份标识信息（包括个人信息主体账号、IP地址）；<strong>个人财产信息（包括本软件内的会员购买和消费记录虚拟财产信息）</strong>；本产品的使用记录（包括<span style="font-size: 12px;">悄然声色</span>应用程序添加/启动/搜索记录、页面访问记录、按钮点击记录）；个人常用设备信息（包括<strong>设备型号、操作系统类型、已安装软件列表、当前运行的进程列表、唯一设备识别码、设备ID、IMEI、MAC地址、IMSI、OAID、Android ID </strong>）。</span>
        </div>
        <div>
          <span style="font-size: 12px;">我们会出于本隐私政策所述的以下目的，收集和使用您的个人信息：</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>1.帮助您完成注册及登录</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">为便于我们为您提供服务，您需要提供基本注册或登录信息，包括<strong>手机号码、电子邮箱地址，并创建您的帐号、用户名和密码</strong>，我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。您的账户名为您的默认昵称，您可以修改和补充您的昵称、性别、生日、职业、所在地信息，这些信息均属于您的“账户信息”。您补充的账户信息将有助于我们为您提供更优的产品与/或服务体验，但如果您不提供这些补充信息，不会影响您使用我们提供的产品与/或服务。此外，我们也提供注册帐号的注销功能，您可以在个人账号管理页面进行操作。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>2.帮助您完成下单、支付并向您交付产品与/或服务</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">当您在订购我们提供的产品与/或服务时，我们会通过系统为您生成购买该产品与/或服务的订单。在下单过程中，您至少需提供您的<strong>个人账号信息、联系电话、支付状态信息</strong>。如果您拒绝提供此类信息，我们将无法完成相关交付服务。为展示您帐号的订单信息，我们会收集您在使用我们提供的产品与/或服务过程中产生的订单信息<strong>（例如您所购买的产品与/或服务信息、具体订单号、订单创建时间、交易金额）</strong>用于向您展示及便于您对订单进行管理。</span>
        </div>
        <div>
          <span style="font-size: 12px;">当您在进行订单支付时，您可以选择第三方支付合作机构（例如微信支付、支付宝、京东支付、京东白条、银联支付）所提供的服务进行支付。支付功能本身并不收集您的个人信息，但我们需要将您的<strong>订单号及交易金额信息</strong>与这些支付机构共享以实现其确认您的支付指令并完成支付。</span>
        </div>
        <div>
          <span style="font-size: 12px;">如您使用的软件版本和硬件设备支持指纹或面容ID支付，您还可以在支付设置中选择开启或关闭对应的<strong>指纹或面容ID</strong>支付功能，我们会在您支付过程中提供相应选项，同时您也可以选择<strong>密码、短信验证码</strong>方式支付。支付的过程中，我们仅收集验证的结果，而不会直接收集您的指纹或面容ID信息。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>3.客服与售后功能</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">当您联系我们的客服人员或使用其他用户响应功能时（例如提出售后申请、个人信息保护投诉或建议、其他客户投诉和需求），我们可能需要您提供必要的个人信息以匹配并核验您的用户身份，以便保障您的帐号与系统安全。我们可能还会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的其他必要信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>4.实现和维护核心功能的正常运行</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">在您使用我们的产品与/或服务过程中，为实现应用语音功能、识别帐号异常状态、了解产品适配性、维护基础功能的正常运行，我们可能会自动收集、储存关于您使用的服务以及使用方式的信息并将这些信息进行关联。我们收集这些信息是为了实现和维护核心功能的正常运行，如您拒绝提供上述权限将可能导致您无法使用我们的产品与/或服务。请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。这些信息包括：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）<strong>日志信息</strong>：当您使用我们的产品与/或服务时，我们会收集您对我们服务的详细使用情况，作为有关网络日志保存，例如您注册/登录本产品的帐号、搜索/添加/启动的悄然声色应用程序、IP地址、电信运营商、网络环境、使用的语言、访问日期和时间、通知打开记录信息。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）<strong>设备信息</strong>：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（<strong>IMEI、MAC地址、IMSI、OAID、Android ID、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、当前运行的进程列表、已安装软件列表、唯一设备标识符、软硬件特征信息</strong>）。</span>
        </div>
        <table border="1" rules="all">
          <tbody>
          <tr class="firstRow">
            <th>
              <span style="font-size: 12px;">设备信息</span>
            </th>
            <th>
              <span style="font-size: 12px;">业务用途</span>
            </th>
          </tr>
          <tr>
            <td>
              <span style="font-size: 12px;">IMEI、MAC地址、IMSI识别码、OAID、AndroidID、唯一设备标识符</span>
            </td>
            <td>
              <span style="font-size: 12px;">为了保障软件与服务的安全运行、运营的质量及效率，检测设备登录状态，当检测到异常的设备登录时，我们可以及时采取安全措施，，保障您账户内信息和资金的安全。</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="font-size: 12px;">设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、当前运行的进程列表、已安装软件列表、软硬件特征信息</span>
            </td>
            <td style="word-break: break-all;">
              <span style="font-size: 12px;">方便快速解决悄然声色应用程序的兼容问题,向您提供我们基本服务和基础功能</span>
            </td>
          </tr>
          </tbody>
        </table>
        <div>
          <span style="font-size: 12px;">（3）<strong>存储权限</strong>：当您使用我们的产品与/或服务时，我们会向您申请存储权限，用于悄然声色应用下载安装及使用垃圾清理等功能。您可以选择关闭授权，但关闭后您将无法使用悄然声色应用的下载安装、垃圾清理等核心功能。当您需要关闭存储权限时，您可以在<strong>系统权限管理（首页-左上角个人中心-隐私设置-系统权限管理）</strong>中随时设置开启或关闭。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（4）<strong>收集和使用已安装软件列表</strong>：为了向您提供本机已安装应用程序的创建悄然声色应用功能及服务，我们获取了您设备已安装的应用程序列表信息之后，您能够根据该列表信息来自主选择需要添加的悄然声色应用程序。当您需要关闭提供已安装应用列表信息时，您可以在<strong>系统权限管理（首页-左上角个人中心-隐私设置-系统权限管理）</strong>中随时设置开启或关闭。请注意，部分Android11及以上设备在首次进入APP时，会向您提示授权允许读取已安装应用列表信息；部分设备的系统设置中未提供该权限的设置功能，因此无法主动向您申请授权开启或关闭。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（5）<strong>当前正在运行的应用程序列表信息</strong>：我们会收集和使用您已添加或正在运行的第三方悄然声色应用程序，在您开启接收消息推送或特定事件提醒的情况下，会通过本产品获取当前正在运行的应用程序列表信息，以便您能够正常收到该第三方悄然声色应用程序内的消息或提醒通知。请注意，我们仅用于判断自身进程是否在存在，我们不会上报或收集与我们App无关的应用程序运行的进程信息。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（6）<strong>消息通知</strong>：我们会通过您设备的系统通知功能，向您推送第三方悄然声色应用的实时消息或活动推送消息，您可选择在设备中关闭本软件的消息通知功能，也可在<strong>通知管理页面（个人中心-更多高级设置-通知管理）</strong>中关闭第三方悄然声色应用的通知功能或设置你希望接收的应用消息。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（7）<strong>安装未知应用权限</strong>（Android 11及以上设备）：为了提升您已创建的悄然声色应用版本兼容性，我们会向您申请使用安装未知应用权限，用于安装更适合您设备运行的<span style="font-size: 12px;">悄然声色</span>应用。当您需要关闭安装未知应用权限时，您可以在<strong>系统权限管理（首页-左上角个人中心-隐私设置-系统权限管理）</strong>中随时设置开启或关闭。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（8）<strong>关联启动权限</strong>：在您开启关联启动权限后，您能够正常运行不同二进制接口的悄然声色应用程序，用于兼容性支持包通信功能。若您创建的<span style="font-size: 12px;">悄然声色</span>应用程序二进制接口与我们的产品不同，在需要用到兼容性支持包来保障<span style="font-size: 12px;">悄然声色</span>应用程序正常运行的情况下，如果无法和兼容性支持包通信，会提示打开关联启动权限。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>5.展示、搜索和正常运行第三方悄然声色应用程序：</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）<strong><span style="font-size: 12px;">悄然声色</span>应用的展示和搜索</strong>：为了让您快速找到所需要的<span style="font-size: 12px;">悄然声色</span>应用，我们会收集和使用您本机已安装的软件列表来为您提供<span style="font-size: 12px;">悄然声色</span>应用展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息，您也可以通过搜索来精准地找到您所需要的<span style="font-size: 12px;">悄然声色</span>应用。请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）<strong>正常运行<span style="font-size: 12px;">悄然声色</span>应用程序</strong>：您已添加或正在后台运行的部分第三方<span style="font-size: 12px;">悄然声色</span>应用程序，例如社交类<span style="font-size: 12px;">悄然声色</span>应用程序需要接收和通知提醒实时消息、地图类<span style="font-size: 12px;">悄然声色</span>应用程序需要实时获取您的位置以便实现导航功能，该等第三方<span style="font-size: 12px;">悄然声色</span>应用程序会在您授权同意的范围内通过我们收集和使用您的<strong>设备信息（IMEI、MAC地址、IMSI、OAID、Android ID、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、当前运行的进程列表、已安装软件列表、唯一设备标识符、软硬件特征信息）、位置信息</strong>。该等第三方<span style="font-size: 12px;">悄然声色</span>应用程序中提供的服务由其开发者负责运营，您使用该第三方<span style="font-size: 12px;">悄然声色</span>应用程序中提供的服务时（包括您向该等第三方提供的任何信息），须受其服务条款及信息保护声明（而非本隐私政策）约束，您需要仔细阅读其条款。本隐私政策仅适用于我们所收集的个人信息，并不适用于任何第三方<span style="font-size: 12px;">悄然声色</span>应用程序提供的服务或第三方的信息使用规则。如您发现这些第三方应用程序内提供的服务存在风险时，建议您终止相关操作以保护您的合法权益。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>6.改善我们的产品和服务</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">我们会收集<strong>IMEI、MAC地址、IMSI、OAID、Android ID、IP地址、使用的语言、访问日期和时间、设备信息、设备品牌、设备识别码、操作系统版本号、屏幕分辨率、客户端版本、某按钮的点击情况、使用时间和频率、某些关键配置的选项值、软件崩溃日志信息</strong>，用于统计我们产品的用户数量、分析产品的使用状况、排查崩溃原因、减少崩溃情况，以不断改进我们的产品；同时，我们可能将您的信息用于软件更新。我们会根据您的使用频率和情况、故障信息、性能信息分析我们产品的运行情况，以确保服务的安全性，并优化我们的产品，提高我们的服务质量。我们不会将我们存储在分析软件中的信息与您提供的个人身份信息相结合。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>7.为您提供安全保障</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">为提高您使用我们及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或我们提供的产品与/或服务相关协议规则的情况，<strong>我们可能会收集、使用或整合您的帐号信息、交易信息、设备信息、日志信息</strong>，来综合判断您帐号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>8.其他用途</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">您了解并同意，在收集您的个人信息后，我们可能通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析并予以商业化的利用，而无需另行获得您的同意。请您注意，如果我们要将您的个人信息用于本隐私政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会通过页面提示、交互流程、公告或其他方式另行征得您的同意。一旦您同意，该等额外用途将视为本隐私政策的一部分，该等额外信息也将适用本隐私政策。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>9.您可自主选择提供个人信息的情形</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">您理解并同意，部分单项服务可能需要您在您的设备中开启特定的访问权限，以实现这些权限所涉及信息的收集和使用。例如：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）在您开启<strong>相册和/或相机（摄像头）权限</strong>后，您能够实现修改账户头像功能、以及在部分<span style="font-size: 12px;">悄然声色</span>/语音的应用中上传、拍摄照片/图片/视频，实现扫描二维码、修改头像图片等功能。该信息属于敏感信息，我们会在征得您的同意后收集该信息，拒绝提供该信息只会导致您无法使用部分功能，但不影响您正常使用我们本身提供的其他服务；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）在您开启<strong>通讯录权限</strong>后，您能够在部分<span style="font-size: 12px;">悄然声色</span>/语音的应用中获取您的通讯录好友信息，以便您在该应用中进行好友状态查询、分享内容、支持备份通讯录及通话记录等功能。本软件不会收集该信息，但第三方<span style="font-size: 12px;">悄然声色</span>应用会在征得您的同意后，获取您的通讯录信息，通讯录信息属于敏感信息，拒绝提供该信息只会导致您无法使用部分功能，但不影响您正常使用我们本身提供的其他服务；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）在您开启<strong>麦克风和相关语音权限</strong>后，您能够在部分<span style="font-size: 12px;">悄然声色</span>/语音的应用中使用语音功能与他人进行信息交互，或与客服联系等。本软件不会收集该信息，但第三方<span style="font-size: 12px;">悄然声色</span>应用会在征得您的同意后，获取您的麦克风权限，麦克风信息属于敏感信息，拒绝提供该信息只会导致您无法使用部分功能，但不影响您正常使用我们本身提供的其他服务；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（4）在您开启<strong>位置权限</strong>后，您能够在部分<span style="font-size: 12px;">悄然声色</span>/语音的应用中与附近的朋友进行信息交互或接收到应用内推送内容等。地理位置信息属于敏感信息，本软件不会收集该信息，但第三方<span style="font-size: 12px;">悄然声色</span>应用或第三方SDK会在征得您的同意后，收集您的地理位置信息，拒绝提供该信息只会导致您无法使用部分功能，但不影响您正常使用我们本身提供的其他服务；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（5）在您开启<strong>日历权限</strong>后，您能够在部分<span style="font-size: 12px;">悄然声色</span>/语音的应用中开启日历提醒信息、开启定时消息通知，用于支持您准时接收上线信息通知等功能。日历提醒信息属于敏感信息，本软件不会收集该信息，但第三方<span style="font-size: 12px;">悄然声色</span>应用会在征得您的同意后收集该信息，拒绝提供该信息只会导致您无法使用部分功能，但不影响您正常使用我们本身提供的其他服务；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（6）在您开启<strong>访问传感器信息权限</strong>后，当您能够在扫一扫场景下通过光线传感器判断黑暗环境，以便为您展示是否选择开启闪光灯或调整亮度；或在您播放视频时，通过传感器信息来适配设备的横竖屏状态。本软件不会收集该信息，但第三方<span style="font-size: 12px;">悄然声色</span>应用会在征得您的同意后，获取<strong>访问传感器信息权限</strong>，拒绝提供该信息只会导致您无法使用部分功能，但不影响您正常使用我们本身提供的其他服务。</span>
        </div>
        <div>
          <span style="font-size: 12px;">您可自主选择是否在您的设备中向我们开启您的<strong>相机（摄像头）、相册（图片库）、通讯录、麦克风、地理位置（位置信息）、日历访问权限、访问传感器信息权限</strong>，以实现这些功能所涉及的信息的收集和使用。当您需要关闭这些功能权限时，大多数移动设备都会支持您的这项需求，您可以在悄然声色的系统权限管理（<strong>首页-左上角个人中心-隐私设置-系统权限管理</strong>）中将这些权限随时开启或关闭。请您注意，您开启任一权限即代表您授权第三方<span style="font-size: 12px;">悄然声色</span>应用可以收集和使用相关信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，第三方<span style="font-size: 12px;">悄然声色</span>应用将不再基于对应权限继续收集和使用相关信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。<strong>请具体查阅</strong><strong><a href="/#/permission">《设备权限调用列表》</a></strong></span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>10.征得授权同意的例外</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">根据相关法律法规规定，以下情形中收集您的信息无需征得您的授权同意：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）与国家安全、国防安全有关的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）与公共安全、公共卫生、重大公共利益有关的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）与犯罪侦查、起诉、审判和判决执行等有关的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（4）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（5）所收集的信息是您自行向社会公众公开的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（7）根据您的要求签订合同所必需的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（8）用于维护我们提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（9）为合法的新闻报道所必需的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（11）法律法规规定的其他情形。</span>
        </div>
      </li>
      <li>
        <h3 id="title2">
          <span style="font-size: 12px;">二、我们如何共享、转让、公开披露您的个人信息</span>
        </h3>
        <div>
          <span style="font-size: 12px;"><strong>1.共享</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">我们不会与悄然声色以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）事先获得您明确的同意或授权；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）基于法定情形下：根据法律法规的规定、诉讼争议解决需要，或行政、司法等有权机关依法提出的要求；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）与关联方共享：为向您提供一致化服务以及便于您进行统一管理，我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，如果我们共享您的个人敏感信息或者关联方改变个人信息的使用目的，将再次征求您的授权同意；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（4）只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（5）应您需求为您处理您与他人的纠纷或争议；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（6）基于协议约定：依据您与我们签署的相关协议(包括在线签署的电子协议及平台规则)或法律文件，有必要向第三方共享时；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（7）基于学术研究；例如为科研机构开展统计或学术研究之必要；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（8）基于符合法律法规的社会公共利益等；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（9）与合作伙伴分享：我们可能会向业务合作伙伴共享为您提供服务所必要的订单信息、账户信息、支付信息。我们的业务合作伙伴包括以下类型：</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>商品或技术服务的供应商：</strong>我们可能会将您的个人信息共享给支持我们功能的供应商，仅为实现本政策中声明的目的，我们的某些服务将由业务合作伙伴提供。以保障为您提供的服务顺利完成，我们可能会将您的个人信息共享给上述合作伙伴，包括基础设施技术服务、技术服务、支付服务、数据处理等。其中可能包括您的联络信息、订单信息、支付信息，我们共享这些信息的目的是可以实现我们产品与/或服务的核心功能，比如我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>第三方商家：</strong>我们必须将您的订单信息与必要交易信息与第三方商家共享，以便其向您提供商品、服务及售后等。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>其他业务合作伙伴：</strong>我们还可能与委托我们进行推广和广告投放的合作伙伴共享信息，但我们仅会向这些合作伙伴提供推广的覆盖面、有效性以及统计类等信息，而不会提供可以识别您身份的个人信息。</span>
        </div>
        <div>
          <span style="font-size: 12px;">请您知悉，即使已经取得您的授权同意，我们也仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并尽量对共享内容中的个人信息进行去标识化处理。其中您的联络信息和地址信息属于个人敏感信息，我们已经采取了“默认号码保护”、“地址隐藏”等举措尽全力保护您的个人信息。对我们仅为实现本隐私政策中声明的目的与之共享个人信息的公司、组织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>2.转让</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）事先获得您明确的同意或授权；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（4）在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新征求您的授权同意。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>3.公开披露</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）根据您的需求，在您明确同意的披露方式下披露您所指定的信息；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>4.共享、转让、公开披露个人信息时事先征得授权同意的例外</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）与国家安全、国防安全有关的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）与公共安全、公共卫生、重大公共利益有关的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）与犯罪侦查、起诉、审判和判决执行等有关的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（5）其他维护公共利益的情形，例如您的信用评价信息需要被公开/共享；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（6）您自行向社会公众公开的个人信息；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span>
        </div>
        <div>
          <span style="font-size: 12px;">根据法律规定，共享、转让、公开披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，我们对此类数据的处理将无需另行向您通知并征得您的同意。请注意，您在使用我们产品与/或服务时自愿发布甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您的交易信息，以及您在评价时选择上传包含个人信息的文字、图片或视频等各种形式的信息。请您在使用我们的服务时更加谨慎地考虑，是否要发布甚至公开分享相关信息。</span>
        </div>
      </li>
      <li>
        <h3 id="title3">
          <span style="font-size: 12px;">三、我们如何保存和保护您的个人信息</span>
        </h3>
        <div>
          <span style="font-size: 12px;"><strong>1.个人信息的保存</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">我们仅在本政策所述目的所必需期间和法律法规要求的最短时限内储存您的个人信息（《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。我们在中华人民共和国境内运营中收集和产生的个人信息，储存在中国境内，以下情形除外：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）法律法规有明确规定；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）获得您的授权同意；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）您使用我们提供的产品与/或服务涉及跨境，我们需要向境外提供您的个人信息的。</span>
        </div>
        <div>
          <span style="font-size: 12px;">针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>2.个人信息的保护措施</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">我们非常重视信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）数据安全技术措施</span>
        </div>
        <div>
          <span style="font-size: 12px;">为保障您的信息安全，我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。我们的服务采取了多种加密技术，例如在某些服务传输协议，我们将利用HTTPS加密传输（例如TLS/SSL等）来防止个人信息传输过程中泄露，采取多重HASH加密技术对您个人的密码信息进行加密保存，并通过隔离技术进行隔离。在个人信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中的安全性。我们还会采用严格的数据访问权限LDAP控制和多重身份认证Token技术保护个人信息，避免数据被违规使用。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）我们为保护个人信息采取的其他安全措施</span>
        </div>
        <div>
          <span style="font-size: 12px;">我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的储存和使用。我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。我们还会举办安全和隐私保护培训课程，加强员工的安全意识以及对于保护信息重要性的认识。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）我们仅允许有必要知晓这些信息的内部员工、合作伙伴访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（4）我们会采取一切合理可行的措施，确保未收集无关的个人信息。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（5）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（6）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的个人信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致个人信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</span>
        </div>
        <div>
          <span style="font-size: 12px;">请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入我们提供的产品与/或服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（7）安全事件</span>
        </div>
        <div>
          <span style="font-size: 12px;">在不幸发生个人信息安全事件后，我们会立即成立专项应急小组，启动应急预案，防止安全事件扩大，并按照法律法规的要求及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
        </div>
        <div>
          <span style="font-size: 12px;">请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入我们的产品与/或服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。请您务必妥善保管好您的帐号、密码及其他身份要素。您在使用我们的产品与/或服务时，我们会通过您的帐号、密码及其他身份要素来识别您的身份。一旦您泄露了前述信息，您可能会蒙受损失，并可能对您产生不利。如您发现帐号、密码及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。</span>
        </div>
      </li>
      <li>
        <h3 id="title4">
          <span style="font-size: 12px;">四、您如何管理个人信息</span>
        </h3>
        <div>
          <span style="font-size: 12px;"><strong>1.访问、更新和删除</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">我们鼓励您更新和修改您的个人信息以使其更准确有效。您能通过悄然声色应用访问您的个人信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充和删除。我们将采取适当的技术手段或提供提交申请的联系渠道，尽可能保证您可以访问、更新和更正自己的个人信息或使用我们的产品与/或服务时提供的其他信息。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，<strong>您可以通过访问悄然声色的个人中心页面（首页-左上角个人中心-点击头像）</strong>查看您的用户头像、昵称、账号、手机号。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。<strong>您可以通过访问悄然声色的个人中心页面（首页-左上角个人中心-点击头像）</strong>修改您的用户头像、昵称、账号、手机号、密码。</span>
        </div>
        <div>
          <span style="font-size: 12px;">您有权查阅我们收集你的信息的情况概要，<strong>您可以通过访问悄然声色的隐私设置页面（首页-左上角个人中心-隐私设置-个人信息收集清单）</strong>查阅我们收集你的信息的情况概要。<strong>请具体查阅</strong><strong><a href="/#/collection">《个人信息收集清单》</a></strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）您可以通过访问个人信息查阅页面<strong>（首页-左上角个人中心-隐私设置-个人信息查阅）</strong>查阅您的个人资料及个人账户相关信息，包括您的用户名、手机号、注册时间。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>2.改变您授权同意的范围或撤回授权</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）您可以在悄然声色应用中删除信息、更改隐私设置以及在操作系统中关闭设备功能等方式改变同意范围或撤回您的授权。如前文所述，我们提供的产品与/或服务的部分功能（包括第三方<span style="font-size: 12px;">悄然声色</span>应用提供的功能或部分功能）需要访问您使用设备的相关权限，包括相机（摄像头）、相册（图片库）、通讯录、麦克风、地理位置（位置信息）、日历访问权限。您可以在授权后随时撤回或停止对该权限的继续授权访问，<strong>您可以通过访问悄然声色的系统权限管理（首页-左上角个人中心-隐私设置-系统权限管理），将这些权限随时开启或关闭</strong>。请您理解，当您执行上述操作后，我们(或第三方<span style="font-size: 12px;">悄然声色</span>应用)将无法继续为您提供撤回同意或授权所对应的服务，但不会影响此前基于您的授权而开展的个人信息处理。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）您也可以随时撤回对于本隐私政策的授权，访问隐私政策页面<strong>（首页-左上角个人中心-隐私设置-隐私政策），点击右上角撤回按钮</strong>可进行隐私政策撤回授权的操作。请您理解，当您执行上述操作后，我们将无法继续为您提供撤回同意或授权所对应的服务，但不会影响此前基于您的授权已经开展的个人信息处理。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>3.注销账户</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">您可以在我们的产品中直接申请注销账户。您可以通过访问<strong>个人中心-个人信息-注销账号</strong>的路径来完成账号注销。您注销账户后，我们将立即停止为您提供产品与/或服务，与该帐号相关的、该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或处理，但法律法规另有规定的除外。在您的帐号被注销前，我们将验证您的个人身份、安全状态、设备信息等信息，并在确认您的身份后帮助您完成注销。您知悉并理解，注销帐号的行为是不可逆的行为，在您的帐号被注销后，我们将删除有关您的相关信息或进行匿名化处理，但法律法规另有规定或根据客户需要就客户使用的数据另行处理的除外。注销成功后，我们将立即删除你的个人信息，或对其进行匿名化处理，您将无法找回该帐号中的任何内容或信息，包括但不限于：个人资料、历史信息、虚拟权益等财产。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>4.获取您的个人信息副本</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">您有权获取您的个人信息副本，您可以通过访问个人信息下载页面<strong>（首页-左上角个人中心-隐私设置-个人信息下载）</strong>获取您的个人信息副本。如果您无法通过上述方式获取我们收集的您的个人信息副本，您随时通过本隐私政策“九、如何联系我们”中的任意方式联系我们。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供相应的个人信息副本。为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的十五个工作日内答复您的请求。对于那些无端重复、需要过多技术手段（例如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span>
        </div>
        <div>
          <span style="font-size: 12px;">5.除上述外，如果您或其他有权主体对相关法律法规赋予的个人信息权利的行使，有任何主张、要求或者疑问，可以通过访问联系我们页面（<strong>首页-左上角个人中心-关于-联系我们</strong>）加入用户反馈QQ群或拨打热线电话与我们联系，我们将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内回复。</span>
        </div>
      </li>
      <li>
        <h3 id="title5">
          <span style="font-size: 12px;">五、对Cookie和同类技术的使用</span>
        </h3>
        <div>
          <span style="font-size: 12px;">1、Cookie和同类技术</span>
        </div>
        <div>
          <span style="font-size: 12px;">Cookie和同类技术（包括脚本、Clickstream、Web 信标、Flash Cookie、内嵌 Web 链接等）是互联网中的通用常用技术。当您使用我们App浏览内嵌Web链接时，我们可能会使用相关技术向你的设备发送一个或多个Cookie 或匿名标识符，以收集、标识你访问、使用本产品时的信息。我们承诺，不会将Cookie 用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie 和同类技术主要为了实现以下功能或服务：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）保障产品与服务的安全、高效运转：我们可能会设置认证与保障安全性的Cookie 或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）帮助您获得更轻松的访问体验：使用此类技术可以帮助您省去重复填写个人信息、输入搜索内容的步骤和流程（示例：记录搜索、表单填写）。</span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）活动分享：我们可能会使用Cookie对浏览活动进行记录，用于向您排查崩溃、延迟的相关异常情况以及探索更好的服务方式。</span>
        </div>
        <div>
          <span style="font-size: 12px;">2、您可根据自己的偏好管理或删除 Cookie。您可以清除计算机或移动设备上保存的所有 Cookie，您有权接受或拒绝Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookie。另外，您也可以清除软件内保存的所有Cookie，但您可能因此无法完全体验我们某些便捷性和安全性的服务功能。</span>
        </div>
        <div>
          <span style="font-size: 12px;">3、网络Beacon和同类技术的使用</span>
        </div>
        <div>
          <span style="font-size: 12px;">除Cookie外，我们网页上常会包含一些电子图象(称为&quot;单像素&quot;GIF文件或&quot;网络Beacon&quot;)等其他同类技术，它们可以帮助网站计算浏览网页的用户或访问某些Cookie。我们通过在我们网站上使用网络Beacon，计算用户访问数量，并通过访问Cookie辨认注册用户。</span>
        </div>
      </li>
      <li>
        <h3 id="title6">
          <span style="font-size: 12px;">六、第三方服务</span>
        </h3>
        <div>
          <span style="font-size: 12px;">为了您能更好的使用本软件，我们接入了部分第三方软件工具开发包（SDK）实现相关目的。我们会严格对合作方获取信息的软件工具开发包（SDK）进行严格的安全检测，以保护数据安全。
我们对接入的相关第三方SDK在目录中列明</span>
        </div>
        <div>
          <span style="font-size: 12px;">我们提供的产品与/或服务可能接入或链接至第三方提供的广告、社交媒体或其他服务（包括网站或其他服务形式）。包括：</span>
        </div>
        <div>
          <span style="font-size: 12px;">1.您可利用“分享”键将我们的产品与/或服务分享到第三方服务，这可能会收集您的信息，例如您的日志信息。使用分享功能可能会关联启动第三方应用进行分享。如您不想第三方获得您的相关信息，请不要使用分享功能；</span>
        </div>
        <div>
          <span style="font-size: 12px;">2.我们通过广告或我们服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站，这可能会关联启动第三方应用。如您不想访问第三方的服务或网站，请不要点击访问或拒绝启动；</span>
        </div>
        <div>
          <span style="font-size: 12px;">3.其他接入第三方服务的情形。例如，为实现本隐私政策中声明的目的，我们可能会接入第三方服务商提供的SDK或其他类似的应用程序，并将我们依照本政策收集的您的某些信息共享给该等第三方服务商，以便提供更好的客户服务和用户体验。<strong>我们的产品本身无任何自启动的场景和机制，出于第三方服务的正常运行必要（例如完成支付或进行信息推送服务），可能会关联启动第三方应用。</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">目前，我们接入的第三方服务商主要包括以下几种类型：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）用于广告展示、广告数据监测/统计等广告相关服务；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）用于手机厂商Push推送、特定事件提醒等消息推送功能；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）用于订单支付、交易行为核验、收入结算、支付信息汇总统计等支付相关服务；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（4）用于第三方帐号登陆、内容分享至第三方产品等的第三方授权服务；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（5）用于维护基础功能的正常运行、提升产品适配性、提供性能检测等的安卓系统第三方服务。</span>
        </div>
        <div>
          <span style="font-size: 12px;">请具体查阅<strong><a href="/#/tzsdk">《第三方SDK说明》</a></strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">我们仅会出于正当、必要、特定的目的共享您的信息。对我们与之共享信息的第三方服务商，我们会要求他们履行相关保密义务并采取相应的安全措施。该等第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本隐私政策）约束，您需要仔细阅读其条款。本隐私政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。如您发现这些第三方社交媒体或其他服务存在风险时，建议您终止相关操作以保护您的合法权益。</span>
        </div>
      </li>
      <li>
        <h3 id="title7">
          <span style="font-size: 12px;">七、未成年人的个人信息保护</span>
        </h3>
        <div>
          <span style="font-size: 12px;">1.如您为不满18周岁的未成年人，我们要求您请您的父母或监护人在仔细阅读本隐私政策，并在征得您的父母或监护人的同意之后，在他们的指导下使用我们的产品或服务。如您已征得您父母的同意使用我们的产品或服务，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露您的个人信息。</span>
        </div>
        <div>
          <span style="font-size: 12px;">2.如果我们发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。如果您认为我们可能不当地持有关于未成年人的信息，请您按照本政策文末所列明的公开联系方式与我们取得联系。</span>
        </div>
      </li>
      <li>
        <h3 id="title8">
          <span style="font-size: 12px;">八、通知和修订</span>
        </h3>
        <div>
          <span style="font-size: 12px;">1.为给您提供更好的服务，我们的业务将不时变化，我们可能会根据我们提供的产品与/或服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。如该等更新造成您在本政策下权利的实质减少或重大变更，我们将在本政策生效前通过应用内公告、推送通知、弹窗提示或其他方式来通知您，您如果不同意该等变更，可以选择停止使用我们的产品与/或服务；如您仍然继续使用我们提供的产品与/或服务，即表示您已充分阅读、理解并同意受经修订的本政策的约束。</span>
        </div>
        <div>
          <span style="font-size: 12px;">2.对于重大变更，我们还会提供更为显著的通知，本隐私政策所指的重大变更包括但不限于：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）个人信息共享或公开披露的主要对象发生变化；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（6）个人信息安全影响评估报告表明存在高风险时。</span>
        </div>
        <div>
          <span style="font-size: 12px;">3.您访问隐私政策页面<strong>（首页-左上角个人中心-隐私设置-隐私政策）可再次</strong>查阅本政策。</span>
        </div>
      </li>
      <li>
        <h3 id="title9">
          <span style="font-size: 12px;">九、如何联系我们</span>
        </h3>
        <div>
          <span style="font-size: 12px;"><strong>1.投诉与举报</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以通过访问联系我们页面（<strong>首页-左上角个人中心-关于-联系我们</strong>）加入用户反馈QQ群或拨打热线电话与我们联系，我们将尽快审核所涉问题，并在核查后会在十五个工作日内反馈您的投诉与举报。</span>
        </div>
        <div>
          <span style="font-size: 12px;"><strong>2.联系我们</strong></span>
        </div>
        <div>
          <span style="font-size: 12px;">如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，您均可以通过如下任一方式与我们取得联系：</span>
        </div>
        <div>
          <span style="font-size: 12px;">（1）加入用户反馈QQ群：<span style="font-size: 12px;">47698888&nbsp;</span>与我们联系；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（2）向我们的 <span style="font-size: 12px;">47698888&nbsp;</span><span style="font-size: 12px;">@qq.com</span> 邮箱发送邮件与我们联系；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（3）写信至我们办公地址：<span style="font-size: 12px;">北京市海淀区</span><span style="font-size: 12px;">上地国际创业园东区D座608A</span> 与我们联系；</span>
        </div>
        <div>
          <span style="font-size: 12px;">（4）联系个人信息保护邮箱：<span style="font-size: 12px;">476898888@qq.com</span> 与我们联系。</span>
        </div>
        <div>
          <span style="font-size: 12px;">一般情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的十五个工作日内向您回复。</span>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  components: { },
  props: {},
  data() {
    return {
      isLoading: true
    };
  },
  watch: {},
  computed: {},
  methods: {
  },
  created() {
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.box{
  font-size: 12px;
}
</style>